<template>
  <Content class="notification-result">
    <TermAndConditionsNewModalWithExpands
      :showing-modal="showWindowModal"
      @i-agree-with-temp-conditions="closeTheWindow"
      @show-modal="closeTheWindow($event)"
    />
    <NotSendSupportersResultModal
      :showingModal="showModal"
      @show-modal="closeModal"
      @redirect-to-supporters="closeModal(false)"
      @redirect-to-share-email="redirectToLandingPage"
    />
      <NotificationReadyResultBase v-if="!confirmEmail"
                            :notificationResultTitle = "{
                              title: 'WE DON’T LIKE TO ASK BUT...',
                              subTitle: 'if you want to see your results when they’re ready ...',
                            }"
                            :is-confirm-email="false"
                            @get-email="updateEmail"
                            @open-window-modal="openWindowModal"
      />
      <ActionButton
      v-if="!confirmEmail"
      :text="'NEXT'"
      @click-button="saveEmail"
      :disabled-button="disableButton"
    />
  </Content>
</template>

<script>
import fingerPrintBrowser from '@helpers/differentsServices';
import { mapGetters } from 'vuex';
import ActionButton from '@innerworks_ds/innerworks-storybook-main/src/stories/Button/ActionButton.vue';
import userBehave from '@constants/userBehave';
import NotificationReadyResultBase from '@innerworks_ds/innerworks-storybook-main/src/stories/NotificationReadyResultBase/NotificationReadyResultBase.vue';
import NotSendSupportersResultModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/NotSendSupportersResultModal/NotSendSupportersResultModal.vue';
import TermAndConditionsNewModalWithExpands from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/TermAndConditionsNewModalWithExpands/TermAndConditionsNewModalWithExpands.vue';

export default {
  name: 'NotificationReadyResult',
  components: {
    ActionButton,
    NotSendSupportersResultModal,
    TermAndConditionsNewModalWithExpands,
    NotificationReadyResultBase,
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
  },
  beforeRouteUpdate() {
    this.confirmEmail = false;
  },
  created() {
    this.$api.userBehaveSite.saveSiteEvent(userBehave.readyResultSharePage);
  },
  mounted() {
  },
  data: () => ({
    email: '',
    disableButton: true,
    confirmEmail: false,
    showModal: false,
    showWindowModal: false,
  }),
  methods: {
    openTheWindow() {
      this.showWindowModal = true;
    },
    closeTheWindow(e) {
      if (!e) {
        this.showWindowModal = false;
      }
      this.showWindowModal = e;
    },
    closeModal(value) {
      this.showModal = value;
    },
    async prepareDataForRequest(checkboxInform) {
      return {
        name: this.getProfile.name,
        surname: this.getProfile.surname ? this.getProfile.surname : '',
        youEmail: '',
        questionId: process.env.QUESTIONNAIRE_ID,
        uniqueId: localStorage.getItem('uniqueId'),
        fingerPrint: fingerPrintBrowser.getClientData(),
        checkboxInform,
      };
    },
    updateEmail(data) {
      this.email = data.email;
      this.disableButton = !data.checkValidationEmail;
    },
    openWindowModal() {
      this.showModal = true;
    },
    redirectToLandingPage() {
      this.$store.dispatch('behaviorComponents/resetStateResultPage');
      this.$store.dispatch('auth/logout');
      this.$router.push({
        name: 'main',
        params: {
          slide: 3,
        },
      });
    },
    saveEmail() {
      if (!this.disableButton) {
        this.confirmEmail = true;
        this.$router.push({ name: 'confirm-email', query: { email: this.email } });
      }
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 400px) {
  .notification-result-subtitle {
    font-size: 23px !important;
  }
}
</style>

<style lang="scss" scoped>
.container {
  height: 100%;
  position: relative;
  font-style: $newDefaultFont;
}
</style>
